import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardContent';
import FootballWeeks, { getDefaultWeek, NFL_SEASON } from '../Util/FootballWeeks';
import { GET_TRACKING_BY_TYPE } from '../Query';
import {
    UPDATE_TEAMS,
    UPDATE_LINES,
    UPDATE_YOUWAGER_LINES,
    UPDATE_DSI_LINES,
    UPDATE_GAMES,
    UPDATE_ANNOUNCERS,
    UPDATE_INJURIES,
    UPDATE_WEATHER,
    UPDATE_ALL_FOR_WEEK,
    UPDATE_DEPTH,
    UPDATE_SNAPS,
    UPDATE_PFR,
    UPDATE_SCHEDULE_DATA,
} from '../Mutation';
import DateFormat from '../Util/DateFormat';
import { amber, purple, red, blue, orange, deepPurple } from '@material-ui/core/colors';
import directClient from '../../modules/Client';
// move styles and query specific bits into their own files or not
// or maybe for now jsut one big switch in here on type
const styles = (theme) => ({
    cardHeader: {
        padding: 6,
    },
});

const youWagerStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(amber[400]),
        backgroundColor: amber[400],
        padding: 6,
    },
});

const theOddsStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(red[400]),
        backgroundColor: red[400],
        padding: 6,
    },
});

const teamsStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(purple[400]),
        backgroundColor: purple[400],
        padding: 6,
    },
});

const gamesStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(deepPurple[700]),
        backgroundColor: deepPurple[700],
        padding: 6,
    },
});

const dsiStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(blue[400]),
        backgroundColor: blue[400],
        padding: 6,
    },
});

class DataSourceUpdater extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedWeek: getDefaultWeek(),
            //lastUpdate: null,
        };

        this.handleSelectedWeekChange = this.handleSelectedWeekChange.bind(this);
        this.refreshLastUpdateTime = this.refreshLastUpdateTime.bind(this);
    }

    componentDidMount() {
        if (!this.props.lastUpdate) {
            this.refreshLastUpdateTime();
        }
    }

    // componentWillReceiveProps(){
    //     if (this.props.external)
    // }

    handleSelectedWeekChange(week) {
        this.setState({
            selectedWeek: week,
        });
    }

    async refreshLastUpdateTime() {
        try {
            const { type } = this.props;
            const { data = {} } = await directClient.query({
                query: GET_TRACKING_BY_TYPE,
                variables: { type },
                fetchPolicy: 'no-cache',
            });
            //console.log('---refreshLastUpdateTime---', data);
            const { trackingDetails } = data;
            if (trackingDetails?.length) {
                const trackingRec = trackingDetails[0];
                this.setState({
                    lastUpdate: trackingRec.lastUpdate,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    getVariables() {
        const { type } = this.props;
        const { selectedWeek } = this.state;
        let weekObj = NFL_SEASON[selectedWeek - 1];
        let year = weekObj.dates[0].year();
        return {
            type,
            week: selectedWeek,
            year,
        };
    }

    async executeUpdataData() {
        const variables = this.getVariables();
        const { mutation } = this.props;
        const mutationParams = {
            mutation,
            variables,
        };

        await directClient.mutate(mutationParams);
        await this.refreshLastUpdateTime();
    }

    render() {
        const { classes, type, typeLabel, externalLastUpdate } = this.props;
        const { selectedWeek, lastUpdate } = this.state;
        const actualLastUpdate =
            externalLastUpdate && new Date(externalLastUpdate) > new Date(lastUpdate)
                ? externalLastUpdate
                : lastUpdate;
        return (
            <Card style={{ margin: 12 }}>
                <CardHeader className={classes.cardHeader}>{typeLabel}</CardHeader>
                <CardContent style={{ padding: 12, display: 'flex', flexDirection: 'column' }}>
                    Updated:
                    <DateFormat date={actualLastUpdate} />
                </CardContent>
                <CardContent
                    style={{ padding: 12, paddingTop: 0, display: 'flex', flexDirection: 'column' }}
                >
                    <FootballWeeks
                        selectedWeek={selectedWeek}
                        onWeekChange={this.handleSelectedWeekChange}
                    />
                    <Button color="primary" onClick={() => this.executeUpdataData()}>
                        Update {typeLabel}
                    </Button>
                </CardContent>
            </Card>
        );
    }
}

export const TeamsUpdater = withStyles(teamsStyles, { withTheme: true })((props) => {
    return <DataSourceUpdater {...props} mutation={UPDATE_TEAMS} type="teams" typeLabel="Teams" />;
});
export const GamesUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return <DataSourceUpdater {...props} mutation={UPDATE_GAMES} type="games" typeLabel="Games" />;
});
export const TheOddsUpdater = withStyles(theOddsStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_LINES}
            type="theodds"
            typeLabel="TheOdds API"
        />
    );
});
export const YouWagerUpdater = withStyles(youWagerStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_YOUWAGER_LINES}
            type="youwager"
            typeLabel="YouWager"
        />
    );
});

export const BetDSIUpdater = withStyles(dsiStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_DSI_LINES}
            type="betdsi"
            typeLabel="BetDSI"
        />
    );
});

export const AnnouncersUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_ANNOUNCERS}
            type="announcers"
            typeLabel="Announcers"
        />
    );
});

export const InjuriesUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_INJURIES}
            type="injuries"
            typeLabel="Injuries"
        />
    );
});

export const WeatherUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_WEATHER}
            type="weather"
            typeLabel="weather"
        />
    );
});

export const AllWeeklyUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_ALL_FOR_WEEK}
            type="all"
            typeLabel="All Weekly"
        />
    );
});
export const PFRUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return <DataSourceUpdater {...props} mutation={UPDATE_PFR} type="pfr" typeLabel="PFR Data" />;
});

export const ScheduleDataUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_SCHEDULE_DATA}
            type="schedules"
            typeLabel="Schedule Data"
        />
    );
});

export const DepthUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater
            {...props}
            mutation={UPDATE_DEPTH}
            type="depth"
            typeLabel="Depth Charts"
        />
    );
});

export const SnapsUpdater = withStyles(gamesStyles, { withTheme: true })((props) => {
    return (
        <DataSourceUpdater {...props} mutation={UPDATE_SNAPS} type="snaps" typeLabel="Snaps Data" />
    );
});
