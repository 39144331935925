// TODO this could be refactored with DataSourceUpdater but it would take a little pulling apart
// mostly the executeMutation and the upload flow would need to be pulled out
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardContent';
import FootballWeeks, { getDefaultWeek, NFL_SEASON } from '../Util/FootballWeeks';
import { GET_TRACKING_BY_TYPE } from '../Query';
import DateFormat from '../Util/DateFormat';
import { orange } from '@material-ui/core/colors';
import directClient from '../../modules/Client';
import Auth from '../../modules/Auth';

const uploaderStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(orange[400]),
        backgroundColor: orange[400],
        padding: 6,
    },
    textarea: {
        width: 200,
        height: 50,
        padding: 8,
        boxSizing: 'border-box',
        border: '2px solid #ccc',
        borderRadius: 4,
        backgroundColor: '#f8f8f8',
        fontSize: 16,
        // resize: 'none',
    },
});

class HeritageUploaderBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedWeek: getDefaultWeek(),
            data: '',
            afterUpload: null,
        };
        this.handleSelectedWeekChange = this.handleSelectedWeekChange.bind(this);
        this.refreshLastUpdateTime = this.refreshLastUpdateTime.bind(this);
    }
    componentDidMount() {
        if (!this.props.lastUpdate) {
            this.refreshLastUpdateTime();
        }
    }

    handleSelectedWeekChange(week) {
        this.setState({
            selectedWeek: week,
        });
    }

    async refreshLastUpdateTime() {
        try {
            const { type } = this.props;
            const { data = {} } = await directClient.query({
                query: GET_TRACKING_BY_TYPE,
                variables: { type },
                fetchPolicy: 'no-cache',
            });
            console.log('---refreshLastUpdateTime---', data);
            const { trackingDetails } = data;
            if (trackingDetails?.length) {
                const trackingRec = trackingDetails[0];
                this.setState({
                    lastUpdate: trackingRec.lastUpdate,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    getVariables() {
        const { type } = this.props;
        const { selectedWeek } = this.state;
        let weekObj = NFL_SEASON[selectedWeek - 1];
        let year = weekObj.dates[0].year();
        return {
            type,
            week: selectedWeek,
            year,
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        try {
            //const data = JSON.parse(value);
            this.setState({
                [name]: value,
            });
        } catch (error) {}
    }

    async uploadData(event) {
        const { data } = this.state;
        const variables = this.getVariables();
        const urlEncoded = encodeURIComponent(data);
        //const encodedData = btoa(data);
        //console.log(encodedData);
        //console.log(urlEncoded);
        const options = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `bearer ${Auth.getToken()}`,
            },
            body: JSON.stringify({ data: urlEncoded, ...variables }),
        };
        const url = `/api/update-lines-data`;
        try {
            const res = await fetch(url, options);
            if (res.status == 200) {
                const resData = await res.json();
                console.log('result', resData);
                this.setState({ data: '' });
            } else {
                const errors = res.errors ? res.errors : {};
                errors.summary = res.message;
                console.error(errors);
            }
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const { classes, type, typeLabel, externalLastUpdate } = this.props;
        const { selectedWeek, lastUpdate, data } = this.state;
        //const jsonData = data ? JSON.stringify(data) : '';
        const actualLastUpdate =
            externalLastUpdate && new Date(externalLastUpdate) > new Date(lastUpdate)
                ? externalLastUpdate
                : lastUpdate;
        return (
            <Card style={{ margin: 12 }}>
                <CardHeader className={classes.cardHeader}>{typeLabel}</CardHeader>
                <CardContent style={{ padding: 12, display: 'flex', flexDirection: 'column' }}>
                    Updated:
                    <DateFormat date={actualLastUpdate} />
                </CardContent>
                <CardContent
                    style={{ padding: 12, paddingTop: 0, display: 'flex', flexDirection: 'column' }}
                >
                    <FootballWeeks
                        selectedWeek={selectedWeek}
                        onWeekChange={this.handleSelectedWeekChange}
                    />
                    <form className={classes.root} noValidate autoComplete="off">
                        <textarea
                            className={classes.textarea}
                            name="data"
                            value={data}
                            onChange={(e) => this.handleInputChange(e)}
                        ></textarea>
                    </form>
                    <Button color="primary" onClick={() => this.uploadData()}>
                        Upload Data
                    </Button>
                </CardContent>
            </Card>
        );
    }
}

export const HeritageUpdater = withStyles(uploaderStyles, { withTheme: true })((props) => {
    return <HeritageUploaderBase {...props} type="heritage" typeLabel="Heritage" />;
});

// if we need to b64 enconde unicode
// function base64ToBytes(base64) {
//     const binString = atob(base64);
//     return Uint8Array.from(binString, (m) => m.codePointAt(0));
// }

// function bytesToBase64(bytes) {
//     const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('');
//     return btoa(binString);
// }

// Usage
//   bytesToBase64(new TextEncoder().encode("a Ā 𐀀 文 🦄")); // "YSDEgCDwkICAIOaWhyDwn6aE"
//   new TextDecoder().decode(base64ToBytes("YSDEgCDwkICAIOaWhyDwn6aE")); // "a Ā 𐀀 文 🦄"
